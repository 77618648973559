"use client";

import Homepage from "@/components/homepage";
import HeaderAuth from "@/components/header-auth";
import Footer from "@/components/ui/footer";
import Link from "next/link";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";

export default function Index() {
  return (
    <main className="min-h-screen flex flex-col">
      <HeaderAuth
        authButtons={[
          <>
            <DropdownMenuItem>
              <Link prefetch={false} href={"/protected/dashboard"}>
                Dashboard
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Link prefetch={false} href={"/support"}>
                Support
              </Link>
            </DropdownMenuItem>
          </>,
        ]}
        buttons={[
          <>
            <Link prefetch={false}
              href="/signup"
              className="text-muted-foreground transition-colors hover:text-foreground min-w-fit"
            >
              Get Started
            </Link>
            <Link prefetch={false}
              href={"/tariffs"}
              className="text-muted-foreground transition-colors hover:text-foreground min-w-fit"
            >
              Tariffs
            </Link>
            <Link prefetch={false}
              href="/terms-of-service"
              className="text-muted-foreground transition-colors hover:text-foreground min-w-fit"
            >
              Terms Of Service
            </Link>
          </>,
        ]}
      />
      <Homepage />
      <Footer />
    </main>
  );
}
