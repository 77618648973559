"use client";

import React, { useState, useEffect, useRef } from "react";
import Typed, { TypedOptions } from "typed.js";

interface TypewriterProps {
  strings: string[];
}

const Typewriter: React.FC<TypewriterProps> = ({ strings }) => {
  const el = useRef<HTMLSpanElement | null>(null);
  const typed = useRef<Typed | null>(null);

  React.useEffect(() => {
    const options : TypedOptions = {
      strings: strings as string[],
      typeSpeed: 80,
      backSpeed: 60,
      smartBackspace: false,
      loop: true,
      loopCount: Infinity,
      shuffle: false,
      backDelay: 2000,
      startDelay: 3000,
      showCursor: true,
      cursorChar: "|",
    };

    typed.current = new Typed(el.current, options);

    return () => {
      if (typed.current) {
        typed.current.destroy();
      }
    };
  }, []);

  return <span ref={el}></span>;
};

export default Typewriter;
