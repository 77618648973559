import Footer from "@/components/ui/footer";
import Link from "next/link";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Typewriter from "@/components/ui/typewritertext";
import { ExternalLink, Eye, Shield, Star } from "lucide-react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { getAllPastDeals } from "@/app/auth-actions";
import { dealInfo } from "@/app/deals/[dealId]/page";
import { networkMap, networkTransactionUrls } from "@/lib/definitions";
import { DataTable } from "./ui/data-table";
import { Checkbox } from "./ui/checkbox";
import { table } from "console";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button } from "./ui/button";

type FeatureCardProps = {
  icon: keyof typeof icons;
  title: string;
  description: string;
};

type TransactionsSectionProps = {
  loading: boolean;
  pastDeals: dealInfo[];
  getTransactionUrl: (
    cryptoKey: string,
    networkValue: string,
    txid: string
  ) => string | null;
};

const LoadingSkeleton: React.FC<{
  width?: string;
  height?: string;
  additionalClasses?: string;
}> = ({ width = "w-full", height = "h-16", additionalClasses = "" }) => (
  <Skeleton className={`${width} ${height} ${additionalClasses}`} />
);

const icons = {
  Eye: <Eye />,
  Star: <Star />,
  Shield: <Shield />,
};

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
}) => (
  <div className="flex flex-grow max-w-md gap-2 px-4 pb-3 border rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
    <div className="p-3 rounded-full">{icons[icon]}</div>
    <div className="mt-2">
      <h1 className="text-lg font-medium">{title}</h1>
      <p className="text-sm text-gray-300">{description}</p>
    </div>
  </div>
);

const HeaderSection: React.FC<{ loading: boolean }> = ({ loading }) => (
  <div className="flex flex-col items-center justify-center mt-28 w-full max-w-3xl mx-auto px-4">
    {loading ? (
      <LoadingSkeleton
        width="w-full"
        height="h-16 sm:h-24 md:h-32 lg:h-40"
        additionalClasses="my-4"
      />
    ) : (
      <motion.h1
        className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-center text-accentv"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        Secure Your Transactions with Confidence
      </motion.h1>
    )}
    {loading ? (
      <LoadingSkeleton
        width="w-full"
        height="h-16 sm:h-24 md:h-32 lg:h-40"
        additionalClasses="my-4"
      />
    ) : (
      <motion.h1
        className="mt-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-center text-accentv"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut", delay: 0.2 }}
      >
        Trust Us to Be Your Reliable Middleman
      </motion.h1>
    )}
    <div className="flex flex-col items-center justify-center pt-2">
      {loading ? (
        <LoadingSkeleton
          width="w-2/3"
          height="h-8 sm:h-10 md:h-12 lg:h-14"
          additionalClasses="my-4"
        />
      ) : (
        <motion.h1
          className="max-w-xl pt-2 text-sm sm:text-base md:text-lg lg:text-xl font-medium leading-5 text-center text-primarytext sm:pt-4 text-slate-200"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.4 }}
        >
          <Typewriter
            strings={["Facilitate", "Manage", "Protect", "Secure", "Innovate"]}
          />
          {
            " and ensure that every exchange is secure, transparent, and trustworthy."
          }
        </motion.h1>
      )}
      {loading ? (
        <LoadingSkeleton
          width="w-1/2"
          height="h-12 sm:h-14 md:h-16 lg:h-20"
          additionalClasses="my-4"
        />
      ) : (
        <Link prefetch={false} href="/signup">
          <motion.button
            className="transition-transform duration-300 ease-in-out transform hover:scale-110 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 mt-4 rounded-lg"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
          >
            + Start a Secure Transaction
          </motion.button>
        </Link>
      )}
    </div>
  </div>
);

const FeaturesSection: React.FC<{ loading: boolean }> = ({ loading }) => (
  <section
    className="flex flex-col items-center justify-center pb-24 my-auto mt-12 space-y-4 w-full"
    id="features"
  >
    {loading ? (
      <LoadingSkeleton
        width="w-1/3"
        height="h-12 sm:h-16 md:h-20 lg:h-24"
        additionalClasses="my-4"
      />
    ) : (
      <h2 className="text-xl sm:text-2xl md:text-3xl font-bold tracking-tighter text-center">
        How It Works
      </h2>
    )}
    <section className="flex flex-col md:flex-row items-stretch justify-center gap-4 px-4 w-full">
      {loading ? (
        <>
          <LoadingSkeleton
            width="w-full max-w-md"
            height="h-40 md:h-48 lg:h-56"
            additionalClasses="my-4"
          />
          <LoadingSkeleton
            width="w-full max-w-md"
            height="h-40 md:h-48 lg:h-56"
            additionalClasses="my-4"
          />
          <LoadingSkeleton
            width="w-full max-w-md"
            height="h-40 md:h-48 lg:h-56"
            additionalClasses="my-4"
          />
        </>
      ) : (
        <>
          <FeatureCard
            icon="Eye"
            title="Initiate a Transaction"
            description="Start by selecting the cryptocurrency and network for your transaction. Define the roles of sender and receiver to get started."
          />
          <FeatureCard
            icon="Star"
            title="Verify the Details"
            description="Review and confirm the transaction details with the other party. Make sure everything is accurate before proceeding."
          />
          <FeatureCard
            icon="Shield"
            title="Securely Complete the Transaction"
            description="Our platform ensures that both parties fulfill their obligations. Funds are securely held until both parties confirm the transaction."
          />
        </>
      )}
    </section>
  </section>
);

const TransactionsSection: React.FC<TransactionsSectionProps> = ({
  loading,
  pastDeals,
  getTransactionUrl,
}) => (
  <section
    className="flex flex-col items-center justify-center pb-24 my-auto mt-12 space-y-4 w-full max-w-7xl"
    id="vouches"
  >
    {loading ? (
      <LoadingSkeleton width="w-full max-w-xs" height="h-8" />
    ) : (
      <motion.h2
        className="text-xl sm:text-2xl md:text-3xl font-bold tracking-tighter text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        Latest Deals
      </motion.h2>
    )}
    {loading ? (
      <LoadingSkeleton width="w-full" height="h-[150px]" />
    ) : (
      <section className="flex flex-col md:flex-row flex-wrap items-center justify-center gap-4 px-4 w-full">
        <motion.div
          className="flex flex-grow min-h-[300px] w-full gap-4 px-6 py-8 pb-6 border rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <div className="overflow-x-auto min-w-full px-4">
            <DataTable
              columns={[
                {
                  id: "select",
                  header: ({ table }) => (
                    <Checkbox
                      checked={
                        table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate")
                      }
                      onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                      }
                      aria-label="Select all"
                    />
                  ),
                  cell: ({ row }) => (
                    <Checkbox
                      checked={row.getIsSelected()}
                      onCheckedChange={(value) => row.toggleSelected(!!value)}
                      aria-label="Select row"
                    />
                  ),
                  enableSorting: false,
                  enableHiding: false,
                },
                {
                  accessorKey: "txid",
                  header: "TxID",
                  cell: ({ row }) => (
                    <Link prefetch={false}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        row.getValue("txid")
                          ? (getTransactionUrl(
                              row.getValue("currency"),
                              row.getValue("network"),
                              row.getValue("txid")
                            ) ?? "404")
                          : "404"
                      }
                      className="flex items-center space-x-1 capitalize"
                    >
                      <span>{row.getValue("txid") || "N/A"}</span>
                      <ExternalLink className="w-4 h-4" />
                    </Link>
                  ),
                },
                {
                  accessorKey: "id",
                  header: "Deal ID",
                  cell: ({ row }) => (
                    <div className="capitalize">{row.getValue("id")}</div>
                  ),
                },
                {
                  accessorKey: "status",
                  header: "Status",
                  cell: ({ row }) => (
                    <div className="capitalize">{row.getValue("status")}</div>
                  ),
                },
                {
                  accessorKey: "network",
                  header: ({ column }) => {
                    return <div className="hidden">Network</div>;
                  },
                  cell: ({ row }) => (
                    <div className="capitalize hidden">
                      {row.getValue("network")}
                    </div>
                  ),
                },
                {
                  accessorKey: "currency",
                  header: "Currency",
                  cell: ({ row }) => (
                    <div className="capitalize">
                      {row.getValue("currency")
                        ? String(row.getValue("currency")).toUpperCase()
                        : "N/A"}
                    </div>
                  ),
                },
                {
                  accessorKey: "amount",
                  header: ({ column }) => {
                    return (
                      <Button
                        variant="ghost"
                        onClick={() =>
                          column.toggleSorting(column.getIsSorted() === "asc")
                        }
                      >
                        Amount
                        <CaretSortIcon className="ml-2 h-4 w-4" />
                      </Button>
                    );
                  },
                  cell: ({ row }) => {
                    const amount = parseFloat(row.getValue("amount"));

                    const formatted = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(amount);

                    return (
                      <div className="text-left font-medium">{formatted}</div>
                    );
                  },
                },
              ]}
              data={pastDeals}
            />
            {/* <Table className="min-w-full">
              <TableCaption>
                A list of the past 30 days transactions
              </TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">TxID</TableHead>
                  <TableHead>Deal ID</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Method</TableHead>
                  <TableHead className="text-right">Amount</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {pastDeals && pastDeals.length > 0 ? (
                  pastDeals.map((deal) => (
                    <TableRow key={deal.id}>
                      <TableCell className="font-medium">
                        <Link prefetch={false}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            deal?.txid
                              ? (getTransactionUrl(
                                  deal.currency,
                                  deal.network,
                                  deal.txid
                                ) ?? "404")
                              : "404"
                          }
                          className="flex items-center space-x-1"
                        >
                          <span>{deal?.txid ? deal?.txid : "N/A"}</span>
                          <ExternalLink className="w-4 h-4" />
                        </Link>
                      </TableCell>
                      <TableCell className="font-medium">{deal.id}</TableCell>
                      <TableCell>{deal.status}</TableCell>
                      <TableCell>{deal.currency.toUpperCase()}</TableCell>
                      <TableCell className="text-right">
                        ${deal.amount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center">
                      No transactions found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table> */}
          </div>
        </motion.div>
      </section>
    )}
  </section>
);

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [pastDeals, setPastDeals] = useState<dealInfo[]>([]);

  const fetchPastDeals = async () => {
    await getAllPastDeals(30).then((deals) => {
      setPastDeals(deals as []);
    });
  };

  useEffect(() => {
    fetchPastDeals();

    const timeout = setTimeout(() => setLoading(false), 300);
    return () => clearTimeout(timeout);
  }, []);

  const getTransactionUrl = (
    cryptoKey: string,
    networkValue: string,
    txid: string
  ): string | null => {
    const cryptoData =
      networkMap[cryptoKey.toLowerCase() as keyof typeof networkMap];
    if (!cryptoData) {
      console.error(`Unsupported cryptocurrency: ${cryptoKey}`);
      return null;
    }

    const networkData = cryptoData.networks.find(
      (net: { value: string }) => net.value === networkValue
    );
    if (!networkData) {
      console.error(
        `Unsupported network: ${networkValue} for cryptocurrency: ${cryptoKey}`
      );
      return null;
    }

    const urlTemplate =
      networkTransactionUrls[
        networkValue as keyof typeof networkTransactionUrls
      ];
    if (!urlTemplate) {
      console.error(
        `No transaction URL template found for network: ${networkValue}`
      );
      return null;
    }

    return urlTemplate.replace("%s", txid);
  };

  return (
    <>
      <main className="flex flex-col items-center justify-between min-h-screen mb-16">
        <HeaderSection loading={loading} />
        <FeaturesSection loading={loading} />
        <TransactionsSection
          loading={loading}
          pastDeals={pastDeals}
          getTransactionUrl={getTransactionUrl}
        />
      </main>
    </>
  );
};

export default Home;
